<template>
  <!-- 用量表补充 -->
  <div>
    <div class="addPage">
      <div class="leftDiv">
        <div class="leftTopDiv title">补充用量表<span class="pageBtn finger btnSure fr" @click="inputLineCode">查询</span></div>
        <div class="leftTopBtm areaFlex1">
          <i-input type="textarea" placeholder="请输入带入库条形码，多个产品换行显示" v-model="lineCode"></i-input>
        </div>
      </div>
      <div class="rightDiv">
        <div class="rightTopDiv title">用量清单<span class="pageBtn finger btnReset fr" @click="goBack">返回</span></div>
        <selfTable :productList="addListColumns1" :productData="addMeterList1"> </selfTable>
        <div class="rightTopDiv marginTop14 title">待补充明细清单<span class="longTextBtn finger btnReset fr marginLeft20">模版导入</span><span class="longTextBtn finger btnReset fr">模版下载</span></div>
        <Table :columns="addListColumns2" :data="addMeterList2" border style="min-height: 400px">
          <template slot-scope="{ row, index }" slot="bar_code">
            <Input placeholder="请输入" size="small" v-model="row.bar_code" @input="changeTiaoma(row.bar_code, index)" style="width: 120px" />
          </template>
          <template slot-scope="{ row, index }" slot="batch_number">
            <Input placeholder="请输入" size="small" v-model="row.batch_number" @on-change="changePihao(row.batch_number, index)" style="width: 120px" />
          </template>
          <template slot-scope="{ row, index }" slot="quantity">
            <Input placeholder="请输入" size="small" v-model="row.quantity" @on-change="changeShuliang(row.quantity, index)" style="width: 120px" type="number" />
          </template>
          <template slot-scope="{ row, index }" slot="production_date">
            <DatePicker type="date" placeholder="请选择" style="width: 140px" v-model="row.production_date" placement="left-start" @on-change="changeProductDate(row.production_date, index)"></DatePicker>
          </template>
          <template slot-scope="{ row, index }" slot="valid_period">
            <DatePicker type="date" placeholder="请选择" style="width: 140px" v-model="row.valid_period" placement="left-start" @on-change="changeDate(row.valid_period, index)"></DatePicker>
          </template>
        </Table>
        <div style="margin: 25px 0">
          <span class="pageBtn finger btnSure fr" @click="sure">确认</span>
        </div>
      </div>
      <!-- <tips-component showModal="delete" @cancleBtn="deleteModel=false" @sureBrn="deleteAddItem(deleteIndex)" v-if="deleteModel"></tips-component> -->
      <!-- 删除模态框 -->
      <Modal v-model="deleteModel" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
        <div class="reviewDiv">
          <p>是否删除记录？</p>
          <div>
            <span class="pageBtn finger btnSure" @click="deleteAddItem(deleteIndex)">确定</span>
            <span class="pageBtn finger btnCancle" @click="deleteModel = false">取消</span>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import selfTable from '@/components/table'
export default {
  name: 'customerScaleAddConsign',
  components: {
    selfTable,
  },
  data() {
    return {
      // 补充时-补充用量表表头-用量清单
      addListColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '用量',
          key: 'use_quantity',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 75,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 75,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  /* style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  }, */
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.getAddlist(params.row)
                    },
                  },
                },
                '添加'
              ),
            ])
          },
        },
      ],
      /* 用量清单 */
      addMeterList1: [
        /* {
          name: '一次性使用消化道吻合器',
          xinghao: 'RV2213',
          sku: 'RV2213',
          yongliang: '1',
          danwei: '个',
        },
        {
          name: '一次性使用消化道吻合器',
          xinghao: 'RV2213',
          sku: 'RV2213',
          yongliang: '1',
          danwei: '个',
        }, */
      ],
      // 补充时-补充用量表表头-待补充明细用量清单
      addListColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          sortable: true,
          minWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 128,
        },
        {
          title: '条码',
          slot: 'bar_code',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '生产日期',
          slot: 'production_date',
          key: 'production_date',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '有效期',
          slot: 'valid_period',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 132,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  /* style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  }, */
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.deleteModel = true
                      this.deleteIndex = param.index
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      /* 待补充明细清单数据 */
      addMeterList2: [],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      deleteModel: false /* 删除模态框 */,
      customer_meter_id: '' /* 用量表id */,
      meter_category: '' /* 用量表类型 */,
      lineCode: '' /* 条形码数据 */,
      purchase_unit_price: '' /* 采购单价 */,
      sale_unit_price: '' /* 销售单价 */,
    }
  },
  methods: {
    /* 分页 */
    changePage(e) {
      this.pages.page = e
    },
    /* 核对待补充用量清单并发送 */
    sure() {
      let addList1 = this.addMeterList1
      let flag = true /* 检查待补充明细清单产品输入的各类参数是否有问题 */
      for (const item of addList1) {
        /* 对同一型号产品逐个验证 */
        let count = Number(item.use_quantity) /* 获取用量清单每件商品的总数 */
        let itemCount = 0 /* 补充用量清单总数 */
        let addList2 = this.addMeterList2.filter(val => {
          return val.id === item.id /* 用明细id进行筛选，得到同一产品规格的产品数组 */
        })
        console.log(addList2)
        for (const item2 of addList2) {
          // 限制数量为非零正整数
          if (!Number(item2.quantity) || Number(item2.quantity) <= 0) {
            this.$Message.error('请输入非零正整数')
            flag = false
            return
          }
          if (!item2.valid_period) {
            this.$Message.error('有效期不能为空')
            flag = false
            return
          }
          if (!item2.batch_number) {
            this.$Message.error('批号不能为空')
            flag = false
            return
          }
          itemCount = itemCount + Number(item2.quantity)
        }
        console.log(itemCount, count)
        if (itemCount != count) {
          this.$Message.error('补充数量与用量表不一致')
          flag = false
          return
        }
      }
      if (flag) {
        /* 如果一致 */
        let addList = this.addMeterList2
        let customer_meter_id = this.customer_meter_id
        let meter_category = this.meter_category
        let line_info = []
        for (const item of addList) {
          let customer_meter_line_id = item.id
          let quantity = item.quantity
          let bar_code = item.bar_code
          let batch_number = item.batch_number
          let valid_period = item.valid_period
          let production_date = item.production_date
          let obj = { customer_meter_line_id, quantity, bar_code, batch_number, valid_period, production_date }
          line_info.push(obj)
        }
        let listData = { customer_meter_id, line_info, meter_category }
        console.log(listData)
        this.$http.put(this.$apiConsign.customerAdd, listData).then(res => {
          if (res.status) {
            this.$router.push('/customerScaleConsign')
          } else {
            this.$Message.error('补充失败')
          }
        })
      }
      sessionStorage.setItem('updataCache','0')
    },
    goBack() {
      let type = 1
      if (this.meter_category === '0') {
        type = 2
      }
      if (this.meter_category === '1') {
        type = 3
      }
      if (this.meter_category === '2') {
        type = 4
      }
      this.$router.push({
        path: '/customerScaleConsign',
        query: {
          type,
        },
      })
    },
    /* 获取用量表细节 */
    getDetail(id) {
      this.$http.get(this.$apiConsign.customerUsageDetail, { customer_meter_id: id }).then(res => {
        if (res.status) {
          let detailList = []
          for (const item of res.data) {
            let id = item.id /* 用量表明细id（唯一） */
            let product_name = item.product_name
            let model_name = item.model_name
            let item_number = item.item_number
            let serial_number = item.serial_number
            let use_quantity = item.use_quantity
            let unit = item.unit
            let production_date = item.production_date
            let valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD') : ''
            let purchase_unit_price = item.purchase_unit_price
            let sale_unit_price = item.sale_unit_price
            let obj = { id, product_name, model_name, item_number, unit, use_quantity, valid_period, purchase_unit_price, sale_unit_price, serial_number, production_date }
            detailList.push(obj)
          }
          this.addMeterList1 = detailList
        }
      })
    },
    /* 获取待补充用量清单 */
    getAddlist(item) {
      let obj = {}
      obj.product_name = item.product_name
      obj.model_name = item.model_name
      obj.item_number = item.item_number
      obj.id = item.id
      obj.bar_code = ''
      obj.batch_number = ''
      obj.quantity = ''
      obj.valid_period = ''
      this.addMeterList2.push(obj)
      console.log(this.addMeterList2)
    },
    /* 删除待补充明细清单 */
    deleteAddItem(index) {
      this.addMeterList2.splice(index, 1)
      this.$Message.success('删除成功')
      this.deleteModel = false
    },
    /* 改变条码 */
    changeTiaoma(item, index) {
      this.$set(this.addMeterList2[index], 'bar_code', item)
    },
    /* 改变批号 */
    changePihao(item, index) {
      this.$set(this.addMeterList2[index], 'batch_number', item)
    },
    /* 改变数量 */
    changeShuliang(item, index) {
      // this.$set(this.addMeterList2[index], 'quantity', item)
      /* let str = item + ''
      let strIndex = str.indexOf('.')
      if (strIndex !== -1) {
        this.$Message.error('不能为小数')
        this.$set(this.addMeterList2[index], 'quantity', null)
      } else {
        this.$set(this.addMeterList2[index], 'quantity', item)
      } */
      let str = item + ''
      let strIndex = str.indexOf('.')
      let symbalIndex = str.indexOf('-')
      this.$set(this.addMeterList2[index], 'quantity', item)
      if (strIndex !== -1) {
        this.$Message.error('不能为小数')
        this.$set(this.addMeterList2[index], 'quantity', null)
      }
      if (symbalIndex !== -1) {
        this.$Message.error('不能为负数')
        this.$set(this.addMeterList2[index], 'quantity', null)
      }
      if (str.length > 8) {
        this.$Message.error('数字长度不超过8')
        this.$set(this.addMeterList2[index], 'quantity', null)
      }
    },
    /* 改变有效期 */
    changeDate(item, index) {
      if (item) {
        item = this.$moment(item).format('YYYY-MM-DD')
        this.$set(this.addMeterList2[index], 'valid_period', item)
      } else {
        this.$set(this.addMeterList2[index], 'valid_period', '')
      }
    },
    // 改变生产日期
    changeProductDate(item, index) {
      if (item) {
        item = this.$moment(item).format('YYYY-MM-DD')
        this.$set(this.addMeterList2[index], 'production_date', item)
      } else {
        this.$set(this.addMeterList2[index], 'production_date', '')
      }
    },
    /* 查询条形码并输入待补充明细用量清单 */
    inputLineCode() {
      let lineCode = this.lineCode
      if (lineCode) {
        let barcode_list = []
        barcode_list = lineCode.split('\n')
        this.$http.post(this.$apiConsign.addLineCode, { barcode_list }, false).then(res => {
          if (res.status) {
            for (const item of res.data) {
              let obj = {}
              obj.product_name = item.name
              obj.model_name = item.model_name
              obj.item_number = item.item_number
              obj.bar_code = item.bar_code
              obj.batch_number = item.batch_number
              obj.quantity = '1'
              obj.production_date = item.production_date
              obj.serial_number = item.serial_number
              obj.valid_period = item.valid_period
              // obj.usage_receive_id = item.usage_receive_id
              obj.product_id = item.product_id
              if (
                this.addMeterList1.find(val => {
                  obj.id = val.id /* 将明细id赋予给扫码添加的产品 */
                  return val.product_name === obj.product_name && val.model_name === obj.model_name
                })
              ) {
                this.addMeterList2.push(obj)
              }
            }
          }
        })
      } else {
        this.$Message.error('请输入条形码')
      }
    },
  },
  created() {
    /* 获取路由传来的用量表id */
    let customer_meter_id = this.$route.query.customer_meter_id
    let meter_category = this.$route.query.meter_category
    this.customer_meter_id = customer_meter_id
    this.meter_category = meter_category
    /* 获取路由传来的用量表类型 */
    let type = this.$route.query.meter_category
    this.meter_category = type
    /* 根据类型显示不同的表格 */
    this.getDetail(customer_meter_id)
  },
}
</script>

<style lang="less" scoped>
.addPage {
  display: flex;
  overflow: hidden;
  /deep/ .areaFlex1 {
    flex: 1;
    .ivu-input-wrapper {
      height: 100% !important;
    }
    .ivu-input {
      height: 100% !important;
    }
  }
  .title {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #525b6d;
    line-height: 25px;
  }
  .leftDiv {
    width: 370px;
    height: 810px;
    border-right: 1px solid #e8eaec;
    padding: 27px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    .leftTopDiv {
      margin-bottom: 21px;
      margin-top: 5px;
    }
  }
  .rightDiv {
    padding: 27px 24px 26px 24px;
    flex: 1;
    overflow-y: auto;
    .rightTopDiv {
      height: 36px;
      line-height: 36px;
      margin-bottom: 14px;
    }
    .marginTop14 {
      margin-top: 14px;
    }
  }
}
</style>