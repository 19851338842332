var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"addPage"},[_c('div',{staticClass:"leftDiv"},[_c('div',{staticClass:"leftTopDiv title"},[_vm._v("补充用量表"),_c('span',{staticClass:"pageBtn finger btnSure fr",on:{"click":_vm.inputLineCode}},[_vm._v("查询")])]),_c('div',{staticClass:"leftTopBtm areaFlex1"},[_c('i-input',{attrs:{"type":"textarea","placeholder":"请输入带入库条形码，多个产品换行显示"},model:{value:(_vm.lineCode),callback:function ($$v) {_vm.lineCode=$$v},expression:"lineCode"}})],1)]),_c('div',{staticClass:"rightDiv"},[_c('div',{staticClass:"rightTopDiv title"},[_vm._v("用量清单"),_c('span',{staticClass:"pageBtn finger btnReset fr",on:{"click":_vm.goBack}},[_vm._v("返回")])]),_c('selfTable',{attrs:{"productList":_vm.addListColumns1,"productData":_vm.addMeterList1}}),_vm._m(0),_c('Table',{staticStyle:{"min-height":"400px"},attrs:{"columns":_vm.addListColumns2,"data":_vm.addMeterList2,"border":""},scopedSlots:_vm._u([{key:"bar_code",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small"},on:{"input":function($event){return _vm.changeTiaoma(row.bar_code, index)}},model:{value:(row.bar_code),callback:function ($$v) {_vm.$set(row, "bar_code", $$v)},expression:"row.bar_code"}})]}},{key:"batch_number",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small"},on:{"on-change":function($event){return _vm.changePihao(row.batch_number, index)}},model:{value:(row.batch_number),callback:function ($$v) {_vm.$set(row, "batch_number", $$v)},expression:"row.batch_number"}})]}},{key:"quantity",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small","type":"number"},on:{"on-change":function($event){return _vm.changeShuliang(row.quantity, index)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}},{key:"production_date",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('DatePicker',{staticStyle:{"width":"140px"},attrs:{"type":"date","placeholder":"请选择","placement":"left-start"},on:{"on-change":function($event){return _vm.changeProductDate(row.production_date, index)}},model:{value:(row.production_date),callback:function ($$v) {_vm.$set(row, "production_date", $$v)},expression:"row.production_date"}})]}},{key:"valid_period",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('DatePicker',{staticStyle:{"width":"140px"},attrs:{"type":"date","placeholder":"请选择","placement":"left-start"},on:{"on-change":function($event){return _vm.changeDate(row.valid_period, index)}},model:{value:(row.valid_period),callback:function ($$v) {_vm.$set(row, "valid_period", $$v)},expression:"row.valid_period"}})]}}])}),_c('div',{staticStyle:{"margin":"25px 0"}},[_c('span',{staticClass:"pageBtn finger btnSure fr",on:{"click":_vm.sure}},[_vm._v("确认")])])],1),_c('Modal',{attrs:{"label-position":"left","width":"400","footer-hide":true,"closable":false,"class-name":"vertical-center-modal"},model:{value:(_vm.deleteModel),callback:function ($$v) {_vm.deleteModel=$$v},expression:"deleteModel"}},[_c('div',{staticClass:"reviewDiv"},[_c('p',[_vm._v("是否删除记录？")]),_c('div',[_c('span',{staticClass:"pageBtn finger btnSure",on:{"click":function($event){return _vm.deleteAddItem(_vm.deleteIndex)}}},[_vm._v("确定")]),_c('span',{staticClass:"pageBtn finger btnCancle",on:{"click":function($event){_vm.deleteModel = false}}},[_vm._v("取消")])])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightTopDiv marginTop14 title"},[_vm._v("待补充明细清单"),_c('span',{staticClass:"longTextBtn finger btnReset fr marginLeft20"},[_vm._v("模版导入")]),_c('span',{staticClass:"longTextBtn finger btnReset fr"},[_vm._v("模版下载")])])}]

export { render, staticRenderFns }